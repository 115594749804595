<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        md="12"
        lg="12"
      >
        <purchases-dashboard :dashboards-data="dashboardsData" />
      </b-col>
    </b-row>

    <b-card
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector ml-50 mr-1"
            />
          </b-col>

          <!-- Clientes -->
          <b-col
            cols="12"
            md="4"
          >
            <v-select
              v-if="categoryOptions"
              v-model="selectedCategory"
              :options="categoryOptions"
              :reduce="category => category.id"
              :clearable="true"
              placeholder="Ver por cliente"
              @search="onSearchCategories"
            >
              <template slot="no-options">
                Lo siento, no se encontraron clientes
              </template>
            </v-select>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
            </div>
          </b-col>
        </b-row>

        <b-row
          class="mt-1"
        >
          <b-col md="3">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="es"
              placeholder="Desde"
              selected-variant="warning"
            />
          </b-col>
          <b-col md="3">
            <b-form-datepicker
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="es"
              placeholder="Hasta"
              selected-variant="warning"
            />
          </b-col>

          <b-col
            md="6"
          >
            <json-excel :data="json_data">
              <b-button
                variant="success"
                @click="exportToExcel"
              >

                Exportar a excel
              </b-button>
            </json-excel>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="position-relative"
        hover
        responsive
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidientes"
        :items="purchasesData"
        :fields="purchasesFields"
        :per-page="perPage"
      >

        <template #head(id)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(supplier)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(total)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(user)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(store)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <template #head(purchase_date)="data">
          <span
            class="text-secondary pointer"
            @click="sortColumn(data.column)"
          >{{ data.label }}
          </span>
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold text-warning"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <template #cell(total)="data">
          Q.{{ data.item.total }}
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <!-- Pagination info -->
          <b-col
            v-if="purchasesMeta"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando del {{ purchasesMeta.from }} al {{ purchasesMeta.to }} de {{ purchasesMeta.total }} registros</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="purchasesMeta"
              v-model="currentPage"
              :total-rows="purchasesMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'
import JsonExcel from 'vue-json-excel'
import PurchasesDashboard from './dashboards/PurchasesDashboard.vue'

export default {
  components: {
    vSelect,
    PurchasesDashboard,
    JsonExcel,
  },

  data() {
    return {
      searchQuery: '',

      sortField: 'id',
      sortDesc: 'desc',

      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,

      purchasesFields: [
        { key: 'id', label: 'Compra' },
        { key: 'supplier', label: 'Proveedor' },
        { key: 'total', label: 'Total' },
        { key: 'user', label: 'Ingresado por' },
        { key: 'store', label: 'Comprado en' },
        { key: 'purchase_date', label: 'Fecha' },
      ],
      purchasesData: null,
      purchasesMeta: null,

      categoryOptions: [],
      selectedCategory: '',

      dashboardsData: {
        earningsChart: {
          series: [60, 40],
        },
        products: [
          {
            id: 1,
            name: 'Producto 1',
            quantity: 10,
          },
          {
            id: 2,
            name: 'Producto 2',
            quantity: 20,
          },
          {
            id: 3,
            name: 'Producto 3',
            quantity: 30,
          },
          {
            id: 4,
            name: 'Producto 4',
            quantity: 40,
          },
          {
            id: 5,
            name: 'Producto 5',
            quantity: 50,
          },
        ],
        suppliers: [
          {
            id: 1,
            name: 'Proveedor 1',
            total: 10,
          },
          {
            id: 2,
            name: 'Proveedor 2',
            total: 20,
          },
          {
            id: 3,
            name: 'Proveedor 3',
            total: 30,
          },
          {
            id: 4,
            name: 'Proveedor 4',
            total: 40,
          },
          {
            id: 5,
            name: 'Proveedor 5',
            total: 50,
          },
        ],
      },

      json_data: [
        {
          name: 'Tony Peña',
          city: 'New York',
          country: 'United States',
          birthdate: '1978-03-15',
          phone: {
            mobile: '1-541-754-3010',
            landline: '(541) 754-3010',
          },
        },
        {
          name: 'Thessaloniki',
          city: 'Athens',
          country: 'Greece',
          birthdate: '1987-11-23',
          phone: {
            mobile: '+1 855 275 5071',
            landline: '(2741) 2621-244',
          },
        },
      ],
      json_meta: [
        [
          {
            key: 'charset',
            value: 'utf-8',
          },
        ],
      ],
    }
  },

  watch: {
    perPage() {
      this.getPurchases()
    },
    currentPage() {
      this.getPurchases()
    },
    searchQuery() {
      this.getPurchases()
    },

    selectedCategory() {
      // this.
    },
  },

  created() {
    this.getPurchases()
  },

  methods: {
    getPurchases() {
      axios
        .get(`purchase?perPage=${this.perPage}&page=${this.currentPage}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.purchasesMeta = response.data
          this.purchasesData = response.data.data
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    sortColumn(field) {
      if (this.sortField === field) {
        if (this.sortDesc === 'asc') {
          this.sortDesc = 'desc'
        } else {
          this.sortDesc = 'asc'
        }
      } else {
        this.sortField = field
        this.sortDesc = 'asc'
      }
      this.getPurchases()
    },

    getCategories() {
      axios
        .get('category?perPage=100&sortField=id&sortDesc=desc&filterField=status&filterValue=Y')
        .then(response => {
          response.data.data.forEach(element => {
            this.categoryOptions.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    onSearchCategories(search, loading) {
      this.categoryOptions = []
      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`category?filterField=status&filterValue=Y&query=${search}`)
            .then(response => {
              loading(false)
              this.categoryOptions = []
              response.data.data.forEach(element => {
                this.categoryOptions.push({
                  label: element.name,
                  id: element.id,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCategories()
      }
    },

    // exportar a excel usando la libraria JSONToExcel
    exportToExcel() {
      //
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 85px;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
