<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-card
          v-if="dashboardsData.earningsChart"
          class="earnings-card"
        >
          <b-row>
            <b-col cols="6">
              <b-card-title class="mb-1">
                Compras del día
              </b-card-title>
              <h5 class="mb-1">
                Q.4055.56
              </h5>
            </b-col>
            <b-col cols="6">
              <!-- chart -->
              <vue-apex-charts
                height="120"
                :options="earningsChart.chartOptions"
                :series="dashboardsData.earningsChart.series"
              />
            </b-col>
          </b-row>
        </b-card>

      </b-col>

      <b-col md="4">
        <b-card
          v-if="dashboardsData.earningsChart"
          class="earnings-card"
        >
          <b-row>
            <b-col cols="6">
              <b-card-title class="mb-1">
                Compras del mes
              </b-card-title>
              <h5 class="mb-1">
                Q.4055.56
              </h5>
            </b-col>
            <b-col cols="6">
              <!-- chart -->
              <vue-apex-charts
                height="120"
                :options="earningsChart.chartOptions"
                :series="dashboardsData.earningsChart.series"
              />
            </b-col>
          </b-row>
        </b-card>

      </b-col>

      <b-col md="4">
        <b-card
          v-if="dashboardsData.earningsChart"
          class="earnings-card"
        >
          <b-row>
            <b-col cols="6">
              <b-card-title class="mb-1">
                Compras del año
              </b-card-title>
              <h5 class="mb-1">
                Q.4055.56
              </h5>
            </b-col>
            <b-col cols="6">
              <!-- chart -->
              <vue-apex-charts
                height="120"
                :options="earningsChart.chartOptions"
                :series="dashboardsData.earningsChart.series"
              />
            </b-col>
          </b-row>
        </b-card>

      </b-col>

      <!-- <b-col md="4">
        <b-card
          v-if="dashboardsData.products"
          class="earnings-card"
        >
          <b-row>
            <b-col cols="6">
              <b-card-title class="mb-1">
                Más comprados en diciembre
              </b-card-title>
              <ul style="list-style:none;">
                <li
                  v-for="product in dashboardsData.products"
                  :key="product.id"
                  style="margin-left: -40px;"
                >
                  <span class="font-small-2">
                    {{ product.name }}
                  </span>
                  <span class="font-small-2 float-right">
                    {{ product.quantity }}
                  </span>
                </li>
              </ul>
            </b-col>

            <b-col cols="6">
              <vue-apex-charts
                height="120"
                :options="earningsChart.chartOptions"
                :series="dashboardsData.earningsChart.series"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col> -->
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    VueApexCharts,
  },
  props: {
    dashboardsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['App', 'Service'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'App',
                    formatter() {
                      return '53%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
}
</script>
